import React, { lazy, Suspense } from "react";


const FrontRouter = lazy(() => import("./FrontRouter"));// 
const HtmlRouter = lazy(() => import("../../contents02/router/HtmlRouter"));//only html


function AppRouter() {
  const pathName = window.location.pathname;
  if (pathName.startsWith("/html")) {
      return (
        <Suspense fallback="..loading">
          <HtmlRouter />
        </Suspense>
      );
  } else {
    return (
      <Suspense fallback="..loading">
        <FrontRouter />
      </Suspense>
    );
  }
}
export default AppRouter;
