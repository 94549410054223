import React from 'react';

import AppRouter from './contents/router/AppRouter';
//import MainRoute from './routes/MainRoute';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  return (<AppRouter />);
}

export default App;
